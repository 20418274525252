<template>
  <CFooter :fixed="false">
    <div>
      <a target="_blank">Easyflip</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }} </span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://upbase.com.ng/">Upbase</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
