<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    class="sidebar"
  >
    <CSidebarBrand
      class="d-md-down-none text-white"
      style="background-color: var(--colorFour);"
      to="/dashboard"
    >
      <p class="h3 text-white">
        EasyFlip
      </p>
    </CSidebarBrand>

    <CRenderFunction class="hover" flat :content-to-render="$options.nav" />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
};
</script>
<style scoped>
a:hover {
  cursor: pointer;
}</style
><style scoped>
.sidebar {
  min-height: 100vh;
  background-color: var(--colorFour);
  color: white;
}

.sidebar ion-icon {
  font-size: 1.4rem !important;
  color: var(--colorOne) !important;
}

.sidebar ion-icon {
  color: var(--colorFour);
  font-size: 1.1rem;
}

.sidebar a:hover {
  color: black !important;
  text-decoration: none !important;
  background-color: var(--colorTwo);
}

.sidebar a:hover ion-icon {
  color: black !important;
}

.sidebar a {
  font-size: 1.06rem;
  color: white !important;
  font-weight: 500;
}

.sidebar a.router-link-exact-active {
  color: black !important;
  background-color: var(--colorTwo);
  background-color: var(--colorOne);
}

.sidebar a.router-link-exact-active ion-icon {
  color: black !important;
}

.sidebar a.router-link-exact-active.navbar-brand,
.sidebar a.navbar-brand:hover {
  background-color: var(--colorFour) !important;
}

.v-application ul,
.v-application ol {
  padding-left: 0px;
}
</style>
