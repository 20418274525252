export default [{
    _name: "CSidebarNav",
    _children: [{
            _name: "CSidebarNavItem",
            name: "Dashboard",
            to: "/dashboard",
            icon: "cil-speedometer",
        },
        {
            _name: "CSidebarNavDropdown",
            name: "Users & Wallet",
            icon: "cil-user",
            items: [
                { name: "All users", to: "/all_users" },
                // { name: "Reset User Account", to: "/all_users/credit_debit_user" },
                // { name: "Update Password", to: "/all_users/update_password" },
                { name: "Transactions", to: "/transaction_log" },
            ],
        },

        {
            _name: "CSidebarNavDropdown",
            name: "GiftCard",
            route: "/giftcard",
            icon: "cil-chart-pie",
            items: [
                { name: "Uploaded Giftcards", to: "/users_giftcard" },
                { name: "Giftcards Categories", to: "/all_giftcard_categories" },
            ],
        },

        {
            _name: "CSidebarNavDropdown",
            name: "Withdrawals",
            route: "/withdrawals",
            icon: "cil-cursor",
            items: [{ name: "All Requests", to: "/withdrawal" }],
        },
        {
            _name: "CSidebarNavDropdown",
            name: "Notifications",
            route: "/notifications",
            icon: "cil-bell",
            items: [{ name: "All Notifications", to: "/notifications" }],
        },
    ],
}, ];